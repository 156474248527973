<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Item</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="itemUpdate" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Venue</label>
                  <select class="custom-select" v-model="form.venue_id">
                    <option selected value="">Open this select menu</option>
                    <option v-for="v in venues" :key="v.id" :value="v.id">{{
                      v.title
                    }}</option>
                  </select>
                  <small class="text-danger" v-if="errors.venue_id">{{
                    errors.venue_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "EditItem",
  data() {
    return {
      name: "Edit Item",
      form: {
        name: "",
        venue_id: "",
      },
      venues: [],
      isSubmitting: false,
      errors: {},
    };
  },
  mounted() {
    this.getData();
    this.getVenue();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("item/" + id)
        .then((result) => {
          this.form = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVenue() {
      apiGet("venue").then(({ data }) => {
        this.venues = data.data;
      });
    },
    itemUpdate() {
      this.isSubmitting = true;

      let id = this.$route.params.id;
      axios
        .post(this.$store.state.api + "item/update/" + id, this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your item has been updated!");
          this.$router.push({ name: "Item" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
